import * as React from 'react';
import SingleColumnLayout from '../layouts/singleColumnLayout/SingleColumnLayout';
import {graphql} from 'gatsby';
import SpeakersGrid from '../components/speaker/SpeakersGrid';
import {GridItemSize} from '../components/soul/layout/grid/GridItemSize';
import SEO from '../components/SEO/SEO';
import {Speaker, SpeakerInSpeechDAO} from '../models/Speaker';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface SpeakersPageProps {
    data: {
        allFile: {
            nodes: SpeakerInSpeechDAO[]
        }
    };
}

const SpeakersPage: React.FC<SpeakersPageProps> = ({data}) => {
    const speakers = data.allFile.nodes
        .map(Speaker.deserializeSpeakerInSpeech)
        .sort((a, b) => a.lastName.localeCompare(b.lastName));

    return (
        <SingleColumnLayout>
            <SEO title="" description=""/>
            <div className="centered-column">
                <h1 className="page-title">Meet our speakers</h1>

                <SpeakersGrid speakers={speakers} size={GridItemSize.ONE_FIFTH}/>
            </div>
        </SingleColumnLayout>
    );
};

export const pageQuery = graphql`
    query AllSpeakers {
        allFile(
            filter: {relativeDirectory: {eq: "speakers"}, extension: {eq: "md"}},
            sort: {fields: [childMarkdownRemark___frontmatter___lastName], order: [ASC]}
        ) {
            nodes {
                relativePath
                id
                childMarkdownRemark {
                    frontmatter {
                        weight
                        firstName
                        lastName
                        avatar {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        jobTitle
                        organization
                    }
                }
            }
        }
    }
`;

export default SpeakersPage;
