import * as React from 'react';
import {GridDensity} from '../soul/layout/grid/GridDensity';
import GridItem from '../soul/layout/grid/GridItem';
import {GridItemSize} from '../soul/layout/grid/GridItemSize';
import SpeakerCard from './SpeakerCard';
import Grid from '../soul/layout/grid/Grid';
import {Speaker} from '../../models/Speaker';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface SpeakersGridProps {
    speakers: Speaker[];
    size: GridItemSize;
}

const SpeakersGrid: React.FC<SpeakersGridProps> = ({speakers, size}) => (
    <Grid density={GridDensity.NARROW} additionalClasses="h-responsive-row">
        {speakers.map(speaker => (
            <GridItem key={speaker.id} size={size}
                      additionalClasses="l-lap--one-third l-palm--one-whole soul-space-stack-bottom-m">
                <SpeakerCard relativePath={speaker.relativePath} speaker={speaker}/>
            </GridItem>
        ))}
    </Grid>
);

export default SpeakersGrid;
